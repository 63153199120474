@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700display=swap');
*,
::before,
::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  background-color: #e6e7e7;
}

a {
  text-decoration: none;
}

.accordion-button {
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
  color: #212529 !important;
  box-shadow: unset !important;
  background-color: unset !important;
}

.accordion-button::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
}

input[type='checkbox'] {
  width: 16px;
  height: 16px;
  cursor: pointer;
  accent-color: #27a376;
}

.notification-title {
  font-size: 16px;
  font-weight: 600;
  color: #111827;
}

.notification-description {
  font-weight: 400;
  color: #687588;
  font-size: 13px;
}

@media (max-width: 576px) {
  input[type='checkbox'] {
    width: 12px;
    height: 12px;
  }

  .notification-title {
    font-size: 12px;
  }

  .notification-description {
    font-size: 9px;
  }
}

.ellipsis-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

#sidebar-menu {
  z-index: 60001;
}

/* Success toast title */
.Toastify__toast--success .notification-title {
  color: #28a745; /* Green for success */
  font-weight: bold;
}

/* Error toast title */
.Toastify__toast--error .notification-title {
  color: #dc3545; /* Red for error */
  font-weight: bold;
}

/* Info toast title */
.Toastify__toast--info .notification-title {
  color: #3498db; /* Blue for info */
  font-weight: bold;
}

/* Warning toast title */
.Toastify__toast--warning .notification-title {
  color: #ffc107; /* Yellow for warning */
  font-weight: bold;
}
